import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Autos2050Logo from "../images/logos/Autos2050_Logo_Small.png"

function SpeakerFormPage() {
  return (
    <Layout>
      <Seo title="Speaker Form" />
      <section className="flex flex-col items-center justify-center py-32 w-full space-y-8">
        <form
          name="Speaker Consideration"
          method="POST"
          data-netlify="true"
          action="/success"
          className="flex flex-col justify-center mx-auto p-2 w-full max-w-xl text-left bg-white space-y-4 lg:p-6 lg:space-y-8"
        >
          <input type="hidden" name="form-name" value="Speaker Consideration" />

          <div>
            <img src={Autos2050Logo} alt="Autos2050 Logo" />
          </div>

          <h3 className="max-w-lg text-base font-bold md:text-lg lg:text-2xl">
            Fill out the form to be considered for a speaking opportunity
          </h3>

          <div className="grid gap-8 grid-cols-1 w-full lg:grid-cols-2">
            <div>
              <label htmlFor="firstName" className="text-sm lg:text-base">
                First Name*
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>

            <div>
              <label htmlFor="lastName" className="text-sm lg:text-base">
                Last Name*
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>

            <div>
              <label htmlFor="email" className="text-sm lg:text-base">
                Email*
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>

            <div>
              <label htmlFor="phoneNumber" className="text-sm lg:text-base">
                Phone Number*
              </label>
              <input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>

            <div className="lg:col-span-2">
              <label
                htmlFor="additionalInformation"
                className="text-sm lg:text-base"
              >
                Additional Information
              </label>
              <textarea
                name="additionalInformation"
                id="additionalInformation"
                cols="20"
                rows="10"
                className="p-2 w-full h-16 border-2 border-gray-300 rounded-sm lg:h-64"
              ></textarea>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="border-2
						font-bold
						text-black
						border-black
						py-2
						px-4
						rounded-full
						min-w-150
						transition
						focus:outline-none
						hover:bg-black
						hover:text-white"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default SpeakerFormPage
